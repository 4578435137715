import axios from "axios";
import { getToken } from "@/utils/cookie";
import { Toast } from "vant";
import router from "@/router/index";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "/prod-api",
  // 超时
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (!["/login", "/captcha"].includes(config.url)) {
      // 判断请求是否是登录接口
      config.headers.token = getToken(); // 如果不是登录接口，就给请求头里面设置token
    }
    return config; // 返回这个配置对象，如果没有返回，这个请求就不会发送出去
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // debugger;
    // if (res.code === "ERR_BAD_REQUEST") {
    //   console.log("token失效");
    // }
    let code = res.data.code; // 获取后端返回的状态码
    if (code === 200) {
      // 成功
      return res.data;
    } else {
      Toast(res.data.message);
      return Promise.reject();
    }
  },
  (error) => {
    if (error.code === "ERR_BAD_RESPONSE") {
      let errMsg = error.response.data.message;
      if (errMsg.indexOf("token") !== -1) {
        Toast("身份令牌失效，系统即将登出");
        router.push({
          path: "/",
        });
      } else {
        Toast(errMsg);
      }
      return Promise.reject(error);
    } else if (error.code === "ERR_BAD_REQUEST") {
      Toast("登录令牌已失效");
    }
  }
);

// 通用下载方法
export function download() {}

export default service;
