import Cookies from "js-cookie";
import router from "@/router/index";

const TokenKey = "tk";

// 获取token
export function getToken() {
  return Cookies.get(TokenKey);
}
// 添加token
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
// 删除token
export function removeToken() {
  return Cookies.remove(TokenKey);
}

// 添加本地存储
export function setStorage(data, key) {
  let StringData = "";
  if (typeof data === "string") {
    StringData = data;
  } else {
    StringData = encodeURI(JSON.stringify(data)); // 加码
  }
  sessionStorage.setItem(key, StringData);
}

// 获取本地存储
export function getStorage(key) {
  let StringData = decodeURIComponent(sessionStorage.getItem(key)); // 解码
  try {
    return JSON.parse(StringData);
  } catch (e) {
    return StringData;
  }
}

// 跳转支付宝
export function aliPay(orderNo) {
  let url =
    `${process.env.VUE_APP_ALIPAY_URL}/pay` +
    `?t=${orderNo}` +
    `&r=${process.env.VUE_APP_WEB_URL}/payment-results/${getToken()}` +
    `&tk=${getToken()}`;
  if (sessionStorage.getItem("keyWord")) {
    url += `&keyWord=${sessionStorage.getItem("keyWord")}`;
  }
  if (sessionStorage.getItem("bd_vid")) {
    url += `&bdVid=${sessionStorage.getItem("bd_vid")}`;
  }
  let browser = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
    navigator.userAgent
  );
  if (!browser) {
    if (location.pathname === "/scan-code") {
      return url;
    } else {
      function uuid() {
        let temp_url = URL.createObjectURL(new Blob());
        let uuid = temp_url.toString(); // blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
        URL.revokeObjectURL(temp_url);
        return uuid.substr(uuid.lastIndexOf("/") + 1);
      }
      router.push({
        path: "/scan-code",
        query: {
          i: uuid(),
          o: orderNo,
        },
      });
    }
  } else {
    location.href = url;
  }
}

export function fillDown(orderNo) {
  let url =
    `${process.env.VUE_APP_ALIPAY_URL}/download` +
    `?t=${orderNo}` +
    `&tk=${getToken()}`;
  location.href = url;
}

// 下载报告
export function handleExport(data) {
  if (!data) return;
  // console.log(data.data);
  data = data.data;
  let fileName = data.fileName + ".pdf";
  let raw = window.atob(data.fileStr);
  let rawLength = raw.length;
  let uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  let blob = new Blob([uInt8Array], { type: "application/pdf" });

  // let blob = new Blob([data], { type: "application/octet-stream" });
  if ("download" in document.createElement("a")) {
    // 不是IE浏览器
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // 下载完成移除元素
    window.URL.revokeObjectURL(url); // 释放掉blob对象
  } else {
    // IE 10+
    window.navigator.msSaveBlob(blob, fileName);
  }
}
