import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "首页" },
    component: HomeView,
  },
  {
    path: "/about-x",
    name: "aboutX",
    meta: { title: "合约" },
    component: () => import("../views/negotiate-page/AboutView.vue"),
  },
  {
    path: "/about-y",
    name: "aboutY",
    meta: { title: "授权同意书" },
    component: () => import("../views/negotiate-page/AuthorizeView.vue"),
  },
  {
    path: "/search",
    name: "search",
    meta: { title: "证件号查询" },
    component: () => import("../views/SearchView.vue"),
  },
  {
    path: "/history",
    name: "history",
    meta: { title: "订单列表" },
    component: () => import("../views/HistoryView.vue"),
  },
  {
    path: "/history-order",
    name: "historyOrder",
    meta: { title: "未支付订单" },
    component: () => import("../views/HistoryOrder.vue"),
  },
  {
    path: "/loading",
    name: "loading",
    meta: { title: "报告查询中" },
    component: () => import("../views/LodingView.vue"),
  },
  {
    path: "/result",
    name: "result",
    meta: { title: "待解锁页" },
    component: () => import("../views/results-page/ResultsMain.vue"),
  },
  {
    path: "/result-query",
    name: "ResultQuery",
    meta: { title: "支付成功页" },
    component: () => import("../views/results-page/ResultsQuery.vue"),
  },
  {
    path: "/result-list",
    name: "ResultList",
    meta: { title: "在线查看报告" },
    component: () => import("../views/results-page/ResultsList.vue"),
  },
  {
    path: "/contack",
    name: "ContackMe",
    meta: { title: "联系我" },
    component: () => import("../views/ContackMe.vue"),
  },
  {
    path: "/payment-results/:id",
    name: "PaymentResults",
    meta: { title: "支付成功回调页" },
    component: () => import("../views/payment-results/Loading.vue"),
  },
  {
    path: "/download",
    name: "Download",
    meta: { title: "导出报告" },
    component: () => import("../views/payment-results/Download.vue"),
  },
  {
    path: "/scan-code",
    name: "scanCode",
    meta: { title: "电脑端扫描支付宝" },
    component: () => import("../views/payment-results/ScanCode.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

export default router;
